import React, { useEffect, useState, useRef } from 'react';

import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
    callPatientButton: {
      background: theme.callBtn,
      margin: '0px 30px 0px 0px',
      color: 'white',
      '&:hover': {
        background: '#232744',
      },
    },
    txtCallStatus: {
       
    },
    contentWrapper: {
      display: 'flex',
      gap: '10px'
    }
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const [callSid, setCallSid] = useState(null);
  const [callStatus, setCallStatus] = useState('await');
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null); // UseRef to store interval

  const handleDisconnectAndRedirect = () => {
    room!.disconnect();
    setTimeout(() => {
      // close tab if it's opened by window.open()
      window.top?.close();
      window.close();
      
      // otherwise location href to finish call page
      const saas_website_address = process.env.REACT_APP_TVMD_SAAS_WEBSITE+'/finishConsultCall.php';
      window.location.href=saas_website_address;
    }, 1000);
  }

  const handleCallPatient = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_CALL_PATIENT_ENDPOINT+'', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          consult_id: URLRoomName,
          // room_sid: room?.sid, // Room SID
        }),
      });

      const data = await response.json();

      if (data.status === 'success') {
        setCallSid(data.call_sid); // Save Call SID to state
        setCallStatus('queued');
        // checkCallStatus(data.call_sid); // Pass Call SID for real-time status checking
      } else {
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error('Error adding participant:', error);
      alert('Failed to add participant.');
    }
  }
  
  const checkCallStatus = async (callSid: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_PATIENT_CALL_STATUS_ENDPOINT}?call_sid=${callSid}`);
      const data = await response.json();
      
      if (data.status === 'success') {
        setCallStatus(data.call_status);

        // Stop polling if call is completed
        if (data.call_status === 'completed' || data.call_status === 'busy' || data.call_status === 'failed' || data.call_status === 'no-answer' || data.call_status === 'in-progress') {
            stopPolling();
        }
      } else {
        console.error('Failed to fetch call status:', data.call_status);        
      }
    } catch (error) {
      console.error('Error checking call status:', error);
    }
  };

  // Start polling call status
  const startPolling = (callSid: string) => {
    stopPolling(); // Ensure no previous intervals are running
    const interval = setInterval(() => {
      checkCallStatus(callSid);
    }, 3000); // Poll every 3 seconds
    pollingIntervalRef.current = interval; // Store interval in ref

  };

  // Stop polling call status
  const stopPolling = () => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null; // Clear the ref
    }
  };

  // Trigger polling when Call SID is set
  useEffect(() => {
      if (callSid) {
          startPolling(callSid);
      }

      // Cleanup on component unmount
      return () => stopPolling();
  }, [callSid]);

  return (
    <>

      <div className={clsx(classes.contentWrapper)}>
        
        Patient Call Status: <b>{callStatus}</b>

        <Button onClick={handleCallPatient} className={clsx(classes.callPatientButton, props.className)} >
          Call Patient
        </Button>

        <Button onClick={handleDisconnectAndRedirect} className={clsx(classes.button, props.className)} data-cy-disconnect>
          Disconnect
        </Button>
      </div>
    </>
  );
}
